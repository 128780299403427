<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="订单号" prop="order_no"></el-table-column>
          <el-table-column label="工程师" prop="engineer_name"></el-table-column>
          <el-table-column label="订单金额" prop="order_price" align="right"></el-table-column>
          <el-table-column label="支付金额" prop="total_fee" align="right"></el-table-column>
          <el-table-column label="优惠金额" prop="discount_fee" align="right"></el-table-column>
          <el-table-column label="结算时间" prop="settlement_time"></el-table-column>
          <el-table-column label="状态" align="center" width="80">
            <template #default="{ row }">
              <el-tag v-if="row.settlement_status === 1" type="success">已结算</el-tag>
              <el-tag v-else type="danger">未结算</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="80">
            <template #default="{ row }">
              <el-button
                v-if="row.settlement_status === 1"
                type="text"
                :icon="View"
                @click="settlement(row.id)"
              >查看</el-button>
              <el-button
                v-else
                type="text"
                :icon="Money"
                @click="settlement(row.id)"
              >结算</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Money, View } from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import EditDialog from './edit.vue';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  // {{admin_host}}/commission/settlement/list
  request.get('/admin/commission/settlement/list', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const editDialog = ref();

function settlement(brandId) {
  editDialog.value.open(brandId);
}

onMounted(() => {
  getList();
});
</script>
